<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="top">
            <div class="top-item topbtn_active" @click="choose(0)">
                <span>vip1</span>
            </div>
            <div class="top-item" @click="choose(1)">
                <span>vip2</span>
            </div>
            <div class="top-item" @click="choose(2)">
                <span>vip3</span>
            </div>
            <div class="top-item" @click="choose(3)">
                <span>vip4</span>
            </div>
        </div>
        <div class="middle">
            <div class="middle-item" ref="middleItem" v-for="(item,index) in list0" :key="index">
                <div class="left">
                    <img :src="item.leftUrl" alt="">
                </div>
                <div class="item-desc">
                    <p>{{item.title}}</p>
                    <p>{{item.content}}</p>
                </div>
                <div class="right">
                    <img v-if="item.rightUrl" :src="item.rightUrl" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
            list0:[],
            list:[
            [
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'统计-精细统计',
                    content:'可以查看知名图纸近100期内的统计数据。如局王-定投-近100期中88',
                    rightUrl:require('../../assets/images/right_arrow.png')
                },
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'统计-冷热分析',
                    content:'可以查看知名图纸的冷热码统计',
                    rightUrl:require('../../assets/images/right_arrow.png')
                },
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'云规规律',
                    content:'多种规律，每期都会更新，也可以查看往期历史云规数据',
                    rightUrl:require('../../assets/images/right_arrow.png')
                },
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'一言九鼎',
                    content:'普通用户点赞+1，会员用户点赞+9，会员用户一票当9票',
                    rightUrl:''
                },
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'VIP标识',
                    content:'自带专属会员标识，名字会金灿灿',
                    rightUrl:''
                },
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'拉黑限制',
                    content:'普通用户最多拉黑50名额，会员用户拉黑名额无限制',
                    rightUrl:''
                },
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'时间30天',
                    content:'从开通当日算起，30天的使用权，若已是会员则自动向后延期',
                    rightUrl:''
                }                
            ],
            // list1:
            [
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'VIP所有功能',
                    content:'享受VIP中的所有功能',
                    rightUrl:require('../../assets/images/right_arrow.png')
                },
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'统计-组合统计',
                    content:'可以查看知名图纸的组合统计',
                    rightUrl:require('../../assets/images/right_arrow.png')
                },
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'时间90天',
                    content:'从开通当日算起，90天的使用权，若已是会员则自动向后延期',
                    rightUrl:''
                },
            ],
            // list2:
            [
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'VIP 2所有功能',
                    content:'享受VIP 2的所有功能(包含VIP1)',
                    rightUrl:require('../../assets/images/right_arrow.png')
                },
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'智能规',
                    content:'若您能看懂规，千万不能错过',
                    rightUrl:require('../../assets/images/right_arrow.png')
                },
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'时间180天',
                    content:'从开通当日算起，180天的使用权，若已是会员则自动向后延期',
                    rightUrl:''
                }
            ],
            // list3:
            [
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'VIP 3所有功能',
                    content:'享受VIP 3中的所有功能(包含VIP1、VIP2)',
                    rightUrl:require('../../assets/images/right_arrow.png')
                },
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'红包打赏',
                    content:'开通打赏功能，分成比例5:5',
                    rightUrl:''
                },
                {
                    leftUrl:require('../../assets/images/analyse.png'),
                    title:'时间360天',
                    content:'从开通当日算起，360天的使用权，若已是会员则自动向后延期',
                    rightUrl:''
                }
            ]
            ]
        }
    },
    components:{
        vHeader
    },
    created(){
        this.list0= this.list[0]
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
    methods:{
        choose(Oldval){
            let divs = document.getElementsByClassName('top-item')
            let val = Oldval;
            for(let i of divs){
                i.className = 'top-item'
            }
            divs[val].className = 'top-item topbtn_active'
            this.list0 = this.list[val]
            if(val != 0){
                let descItemFirst = this.$refs.middleItem[0]
                descItemFirst.addEventListener('click',()=>{
                    //改变呈现内容
                    let newVal = Number(val)
                    newVal--;
                    this.list0 = this.list[newVal]
                    //改变高亮位置
                    console.log(divs);
                    for(let i of divs){
                        i.className = 'top-item'
                    }
                    divs[newVal].className = 'top-item topbtn_active'
                    this.list0 = this.list[newVal]
                    if(newVal>0)(
                        val = newVal
                    )
                })
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .container{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #ddd;
        .top{
            width: 100%;
            height: 30px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .top-item{
                width: 25%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                span{
                    font-size: 16px;
                }
            }
        }
        .middle{
            width: 100%;
            height: auto;
            margin-top:6px;
            .middle-item{
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                background-color: #fff;
                .left{
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 5px;
                    margin: 0 10px;
                    border: 1px solid #f4ea2a;
                    border-radius: 50%;
                    img{
                        width: 30px;
                        height: 30px;
                        
                    }
                }
                .item-desc{
                    p{
                        font-size: 12px;
                        width: 280px;
                        &:first-of-type{
                            color: #c3c33c;
                        }
                        &:last-of-type{
                            color: #000;
                        }
                    }
                }
                .right{
                    float:right;
                    img{
                        width: 20px;
                        height:20px;
                    }
                }
            }
        }
    }
    .topbtn_active{
        border-bottom: 3px solid rgb(224, 166, 58);
        color:  rgb(224, 166, 58);
    }
</style>